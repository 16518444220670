/**@jsx jsx */
import { jsx } from "theme-ui";

const UploadIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M13 19L13 15 16 15 12 10 8 15 11 15 11 19z"></path>
    <path d="M7,19h2v-2H7c-1.654,0-3-1.346-3-3c0-1.404,1.199-2.756,2.673-3.015l0.581-0.102l0.192-0.558C8.149,8.274,9.895,7,12,7 c2.757,0,5,2.243,5,5v1h1c1.103,0,2,0.897,2,2s-0.897,2-2,2h-3v2h3c2.206,0,4-1.794,4-4c0-1.88-1.305-3.462-3.056-3.888 C18.507,7.67,15.56,5,12,5C9.244,5,6.85,6.611,5.757,9.15C3.609,9.792,2,11.82,2,14C2,16.757,4.243,19,7,19z"></path>
  </svg>
);

export function UploadFile({
  handleClick,
  fileName,
  label,
  index,
  disabled = false,
}: {
  handleClick: any;
  fileName: string;
  label: string;
  index: any;
  disabled?: boolean;
}) {
  return (
    <button
      type="button"
      sx={{
        display: "flex",
        alignItems: "center",
        boxShadow: "inset 0px 0.5px 4px rgba(96, 97, 112, 0.32)",
        borderRadius: ["16px", "64px"],
        height: "56px",
        overflow: "hidden",
        bg: "transparent",
        outline: "none",
        border: "none",
        p: 0,
        m: 0,
        color: "text",
        position: "relative",
        cursor: "pointer",
        width: "100%",
        opacity: disabled && 0.5,
      }}
      onClick={() => handleClick(index)}
      disabled={disabled}
    >
      <div
        sx={{
          mx: 4,
          height: "100%",
          position: "relative",
          width: "100%",
        }}
      >
        <span
          sx={{
            fontSize: fileName ? 0 : 2,
            lineHeight: fileName ? 1 : 2,
            position: "absolute",
            top: fileName && 2,
            fontWeight: fileName ? 700 : "heading",
            textDecoration: fileName && "underline",
            color: (t) => (fileName ? "text" : t.colors.dark[0]),
            transition: "200ms linear",
            left: 0,
          }}
        >
          {label}
        </span>
        <div
          sx={{
            border: "none",
            p: 0,
            bg: "transparent",
            outline: "none",
            fontWeight: "body",
            fontSize: 1,
            lineHeight: 1.5,
            position: "absolute",
            bottom: 2,
            left: 0,
            color: "text",
            width: "100%",
            textAlign: "left",
          }}
        >
          {fileName}
        </div>
        <div
          sx={{
            position: "absolute",
            bg: "primary",
            right: "-32px",
            px: 2,
            height: "100%",
            color: "background",
          }}
        >
          <div
            sx={{
              height: "48px",
            }}
          >
            <UploadIcon />
          </div>
        </div>
      </div>
    </button>
  );
}
