/**@jsx jsx */
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import { jsx, Label, Select } from "theme-ui";

type SelectFieldProps = InputHTMLAttributes<HTMLSelectElement> & {
  label: string;
  name: string;
  options: any;
  value?: any;
};

export function SelectField({ label, options, ...props }: SelectFieldProps) {
  const [field, { error, touched }] = useField(props);
  return (
    <div
      sx={{
        width: "100%",
        textAlign: "center",
      }}
    >
      <Label
        htmlFor={field.name}
        sx={{
          px: [3, 4],
          display: "flex",
          position: "relative",
          boxShadow:
            touched && error
              ? "inset 0px 0.5px 4px rgba(255, 0, 0, 1)"
              : "inset 0px 0.5px 4px rgba(96, 97, 112, 0.32)",
          borderRadius: ["16px", "64px"],
          height: "56px",
          flexDirection: "column",
          alignItems: "flex-start",
          bg: "background",
        }}
      >
        <span
          sx={{
            fontSize: 0,
            lineHeight: 1,
            mt: 2,
            fontWeight: 700,
            textDecoration: "underline",
            color: (t) => (touched ? "text" : t.colors.dark[0]),
          }}
        >
          {label}
        </span>
        <select
          {...field}
          {...props}
          id={field.name}
          sx={{
            border: "none",
            p: 0,
            outline: "none",
            fontSize: 2,
            lineHeight: 1.5,
            bottom: 2,
            mt: 2,
            color: "text",
            width: "100%",
          }}
        >
          <option
            value={""}
            sx={{
              fontFamily: "body",
              bg: "footer",
              border: "none",
            }}
          >
            {props.placeholder ? `Elige ${props.placeholder}` : "Elige"}
          </option>
          {options.map((option) => {
            return (
              <option
                value={option.value}
                key={option.id}
                sx={{
                  fontFamily: "body",
                  bg: "footer",
                  border: "none",
                  textTransform: "capitalize",
                  color: "text",
                }}
              >
                {option.name ? option.name : option.value}
              </option>
            );
          })}
        </select>
      </Label>
      {touched && error ? (
        <div
          sx={{
            fontSize: [0],
            color: (t) => t.colors.dark[1],
            fontWeight: 700,
            textAlign: "left",
            mt: 1,
          }}
        >
          {error}
        </div>
      ) : null}
    </div>
  );
}
