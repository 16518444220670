/**@jsx jsx */
import { jsx, Input, Label, Textarea, Select } from "theme-ui";
import { useField } from "formik";
import { Fragment, InputHTMLAttributes, useState } from "react";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  inputType: "textarea" | "input" | "select";
  type: string;
};

export function InputField({ label, inputType, ...props }: InputFieldProps) {
  let Component;
  switch (inputType) {
    case "textarea":
      Component = Textarea;
      break;
    case "select":
      Component = Select;
    default:
      Component = Input;
  }

  const [field, { error, touched }] = useField(props);
  const [change, setChange] = useState(false);
  return (
    <div>
      <Label
        sx={{
          border: (t) => touched && error && `1px solid red`,
          display: "flex",
          alignItems: "center",
          boxShadow: "inset 0px 0.5px 4px rgba(96, 97, 112, 0.32)",
          borderRadius: ["16px", "24px"],
          height: inputType === "textarea" ? "128px" : "56px",
          overflow: "hidden",
        }}
      >
        <div
          sx={{ mx: 4, height: "100%", position: "relative", width: "100%" }}
        >
          <span
            sx={{
              fontSize: change || touched ? 0 : 2,
              lineHeight: change || touched ? 1 : 2,
              position: "absolute",
              top: (change || touched) && 2,
              fontWeight: change || touched ? 700 : "heading",
              textDecoration: (change || touched) && "underline",
              color: (t) => (change || touched ? "text" : t.colors.dark[0]),
              transition: "200ms linear",
            }}
          >
            {label}
          </span>
          <Component
            {...field}
            {...props}
            id={field.name}
            onFocus={() => setChange(true)}
            sx={{
              border: "none",
              p: 0,
              outline: "none",
              fontWeight: "heading",
              fontSize: 2,
              lineHeight: 1.5,
              position: "absolute",
              bottom: 2,
              height: inputType === "textarea" && "88px",
              resize: "none",
            }}
          />
        </div>
      </Label>
      {error ? <span sx={{ ml: 4, pt: 2 }}>{error}</span> : null}
    </div>
  );
}
